import React from 'react';

const Portfolio: React.FC = () => {
  const images = [
    {
      src: '/kitchen_final_view.webp',
      alt: 'Kitchen Final View',
      title: 'Custom Kitchen Cabinets'
    },
    {
      src: '/kitchen_cabinets_painted.webp',
      alt: 'Kitchen Cabinets Painted',
      title: 'Painted Cabinet Finish'
    },
    {
      src: '/kitchen_cabinets_installation.webp',
      alt: 'Kitchen Cabinets Installation',
      title: 'Cabinet Installation'
    },
    {
      src: '/kitchen_alternate_angle.webp',
      alt: 'Kitchen Alternate Angle',
      title: 'Kitchen Design'
    },
    {
      src: '/final_shower_design.webp',
      alt: 'Final Shower Design',
      title: 'Custom Shower Design'
    },
    {
      src: '/shower_tiling.webp',
      alt: 'Shower Tiling',
      title: 'Shower Tile Work'
    },
    {
      src: '/shower_tiling_progress.webp',
      alt: 'Shower Tiling Progress',
      title: 'Tile Installation'
    },
    {
      src: '/laundry_room_shelf_cabinet.webp',
      alt: 'Laundry Room Shelf Cabinet',
      title: 'Laundry Room Cabinets'
    },
    {
      src: '/laundry_room_update.webp',
      alt: 'Laundry Room Update',
      title: 'Updated Laundry Room'
    },
    {
      src: '/table_with_inlay.webp',
      alt: 'Table with Inlay',
      title: 'Custom Table Design'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 pt-20">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-old-world-blue mb-8 text-center">Our Portfolio</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {images.map((image, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="relative pb-[75%]">
                <img
                  src={image.src}
                  alt={image.alt}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
              <div className="p-4">
                <h3 className="text-lg font-semibold text-old-world-blue">{image.title}</h3>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <p className="text-xl mb-6">Ready to start your project?</p>
          <a 
            href="tel:904-524-0677"
            className="inline-block bg-old-world-blue text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Call Now: 904-524-0677
          </a>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
