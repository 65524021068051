import React from 'react';

const Home: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div 
        className="relative h-screen bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/kitchen_final_view.webp')`,
          minHeight: '600px',
          marginTop: '-64px' // Adjust for nav height
        }}
      >
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-white px-4">
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              Old World Cabinets & Tile
            </h1>
            <p className="text-xl md:text-2xl mb-8 max-w-2xl mx-auto">
              Crafting beautiful, custom cabinetry and expert tile installations for your home
            </p>
            <a
              href="tel:904-524-0677"
              className="bg-white text-old-world-blue px-8 py-3 rounded-lg text-lg font-semibold hover:bg-gray-100 transition-colors inline-block"
            >
              Call Now: 904-524-0677
            </a>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4 text-old-world-blue">Custom Cabinets</h2>
            <p className="text-gray-700 mb-4">
              We specialize in creating beautiful, custom-made cabinets that perfectly fit your space and style. 
              Our expert craftsmen use only the finest materials to ensure lasting quality.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4 text-old-world-blue">Professional Tile Work</h2>
            <p className="text-gray-700 mb-4">
              From elegant bathroom renovations to stunning kitchen backsplashes, 
              our tile installation services bring your vision to life with precision and expertise.
            </p>
          </div>
        </div>

        <div className="mt-12 bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4 text-old-world-blue">Why Choose Us?</h2>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>Over 20 years of experience in custom cabinetry and tile work</li>
            <li>Personalized service and attention to detail</li>
            <li>High-quality materials and craftsmanship</li>
            <li>Free consultations and estimates</li>
            <li>Licensed and insured</li>
          </ul>
        </div>

        <div className="mt-12 text-center">
          <h2 className="text-2xl font-semibold mb-4 text-old-world-blue">Ready to Start Your Project?</h2>
          <p className="text-gray-700 mb-6">
            Contact us today to schedule a consultation and bring your vision to life.
          </p>
          <a 
            href="tel:904-524-0677"
            className="inline-block bg-old-world-blue text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Call Now: 904-524-0677
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
