import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const HamburgerMenu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="md:hidden fixed top-0 left-0 right-0 z-50">
      {/* Hamburger Button */}
      <button 
        onClick={toggleMenu}
        className="absolute top-4 right-4 z-60 p-2 bg-old-world-blue rounded-md"
        aria-label="Toggle Menu"
      >
        {isMenuOpen ? (
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 w-6 text-white" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M6 18L18 6M6 6l12 12" 
            />
          </svg>
        ) : (
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 w-6 text-white" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M4 6h16M4 12h16M4 18h16" 
            />
          </svg>
        )}
      </button>

      {/* Mobile Menu Overlay */}
      {isMenuOpen && (
        <div 
          className="fixed inset-0 bg-old-world-blue bg-opacity-95 z-50 flex flex-col justify-center items-center"
          onClick={closeMenu}
        >
          <nav className="text-center">
            <ul className="space-y-6">
              <li>
                <Link
                  to="/"
                  className="block text-4xl text-white font-bold hover:text-gray-200 py-4"
                  onClick={closeMenu}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/portfolio"
                  className="block text-4xl text-white font-bold hover:text-gray-200 py-4"
                  onClick={closeMenu}
                >
                  Portfolio
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="block text-4xl text-white font-bold hover:text-gray-200 py-4"
                  onClick={closeMenu}
                >
                  Contact
                </Link>
              </li>
              <li>
                <a
                  href="tel:904-524-0677"
                  className="block text-4xl text-white font-bold hover:text-gray-200 py-4"
                  onClick={closeMenu}
                >
                  904-524-0677
                </a>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
