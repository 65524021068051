import React from 'react';

const Contact: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 pt-20">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-old-world-blue mb-8 text-center">Contact Us</h1>
        
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
          <div className="aspect-w-16 aspect-h-9">
            <iframe 
              src="https://docs.google.com/forms/d/e/1FAIpQLSfQVr413fiUYgXXIX5ADTlHdailAk0XSmZX5yevgfJsueiNLQ/viewform?embedded=true" 
              className="w-full h-[800px] border-0"
              title="Contact Form"
            >
              Loading…
            </iframe>
          </div>
        </div>

        <div className="mt-12 text-center">
          <p className="text-xl mb-4">Prefer to call?</p>
          <a 
            href="tel:904-524-0677"
            className="inline-block bg-old-world-blue text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Call Now: 904-524-0677
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
