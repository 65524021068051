import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import HamburgerMenu from './components/HamburgerMenu';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        {/* Desktop Navigation - Hidden on mobile, visible on md screens and up */}
        <nav className="hidden md:block bg-white p-4 shadow-md fixed top-0 left-0 right-0 z-40">
          <ul className="flex justify-center space-x-8">
            <li><Link to="/" className="text-old-world-blue hover:text-gray-600">Home</Link></li>
            <li><Link to="/portfolio" className="text-old-world-blue hover:text-gray-600">Portfolio</Link></li>
            <li><Link to="/contact" className="text-old-world-blue hover:text-gray-600">Contact</Link></li>
          </ul>
        </nav>

        {/* Mobile Navigation (Hamburger Menu) - Visible only on mobile */}
        <div className="md:hidden">
          <HamburgerMenu />
        </div>

        {/* Main Content Area */}
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>

        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
