import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-old-world-blue text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
            <p className="mb-2">Phone: <a href="tel:904-524-0677" className="hover:text-gray-300">904-524-0677</a></p>
            <p>Serving Jacksonville and surrounding areas</p>
          </div>
          
          <div>
            <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-gray-300">Home</Link></li>
              <li><Link to="/portfolio" className="hover:text-gray-300">Portfolio</Link></li>
              <li><Link to="/contact" className="hover:text-gray-300">Contact</Link></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-xl font-semibold mb-4">Services</h3>
            <ul className="space-y-2">
              <li>Custom Cabinets</li>
              <li>Cabinet Refinishing</li>
              <li>Tile Installation</li>
              <li>Custom Woodworking</li>
            </ul>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t border-blue-700 text-center">
          <p className="mb-2">&copy; {new Date().getFullYear()} Old World Cabinets & Tile. All rights reserved.</p>
          <p>
            Designed by{' '}
            <a 
              href="https://www.noticememedia.com" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-white hover:text-gray-300 underline"
            >
              Notice Me Media
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
